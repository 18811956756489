import React, { useEffect, memo } from "react";

import SEO from "../components/layout/seo";
import SubHeader from "../components/layout/subheader";

import Space from "../components/space";

import Aos from 'aos';

import { useStaticQuery, graphql, Link } from 'gatsby';

import Img from "gatsby-image";




const AEmpresaBackground = ({name, children}) =>
{
    const data = useStaticQuery(graphql`
        query {
            background: file(relativePath: { eq: "a-empresa.png" }) {
                childImageSharp {
                    fluid(maxWidth: 589, maxHeight: 879) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    if ( name === "a-empresa.png" ) {
        return <Img fluid={data.background.childImageSharp.fluid} alt="" />
    }
}




const onFocus = () =>
{
    //console.log('PaginaAEmpresa:','onFocus');
    Aos.refresh();
}

const TempPaginaAEmpresa = () =>
{
    useEffect(() =>
    {
        //
        // componentDidMount

        //console.log('PaginaAEmpresa:','render');

        if ( typeof Aos !== `undefined` && typeof window !== `undefined` ) {
            setTimeout(function() {
                //Aos.refresh();
                Aos.init();
            }, 500);
            window.addEventListener("focus", onFocus);
        }

        //
        // componentWilUnmount

        return () => {
            //console.log('PaginaAEmpresa:','unmount');

            if ( typeof Aos !== `undefined` && typeof window !== `undefined` )
            {
                window.removeEventListener("focus", onFocus);
                //Aos.disable();
            }
        };
    }, []);

    return (
        <>
            <SEO title="A empresa" />
            <SubHeader pagename="A empresa" bg="page-bg-a-empresa.jpg" />

            <div style={{marginBottom: 100}}></div>
            <div className="page__body page__a-empresa">
                <div className="page__content">
                    <div className="page__background"><AEmpresaBackground name="a-empresa.png" /></div>
                    <h2>Seja mais competitivo<br />e evite armadilhas fiscais!</h2>
                    <p className="text-container" data-aos="fade-in" data-aos-duration="1000">A A&amp;R Inteligência Fiscal e Tributária vem crescendo com seu dinamismo, afinal, todos os negócios são baseados em pessoas. Por esse motivo, focamos em construir um relacionamento de confiança com nossos clientes, onde quer que eles estejam é muito importante para nós da A&amp;R.</p>
                    <p className="text-container" data-aos="fade-in" data-aos-duration="1000">Nosso trabalho possui um relacionamento sólido, com o objetivo de garantir um atendimento confiável. Este é o caminho que seguimos para criar valor e garantir a melhor experiência do nosso cliente, pois, juntos, nós fazemos acontecer para seu negócio.</p>
                    <p className="text-container" data-aos="fade-in" data-aos-duration="1000">Trabalhamos com foco, palavra que define o que bem o que cada negócio deve possuir para alcançar o sucesso. Atualmente vivemos em um mundo muito dinâmico onde empresas sem foco perdem muito quando o assunto é resultado. Muitas empresas perdem tempo em tarefas que não são o seu core e em alguns casos acabam cometendo sérios erros.</p>
                    <p className="text-container" data-aos="fade-in" data-aos-duration="1000">Por esse motivo, com serviço de inteligência fiscal e tributária, levamos ao cliente soluções tributárias que visam aumentar a competitividade no mercado e recuperar créditos tributários administrativamente, com segurança e agilidade para empresas de todos os regimes tributários.</p>
                    <p className="text-container" data-aos="fade-in" data-aos-duration="1000">Poder contar com profissionais especialistas em inteligência fiscal e tributária é essencial para todos os modelos de negócio, afinal, empresários podem enfrentar obstáculos inesperados que podem acabar com investimentos que tinham tudo para prosperar.</p>
                    <p className="text-container" data-aos="fade-in" data-aos-duration="1000">O time da A&amp;R Inteligência Fiscal e Tributária passa por constantes atualizações, por isso, somos uma empresa capacitada em oferecer a correta interpretação de leis e normas regulatórias, possibilitando ao seu negócio atuar de forma eficiente em todas as questões tributárias.</p>
                    <Space altura="20" />
                    <p className="link" data-aos="fade-in" data-aos-duration="1000"><Link to="/nossos-servicos/">Conheça mais sobre nossos serviços aqui</Link></p>
                </div>
            </div>
            <div style={{marginBottom: 100}}></div>
        </>
    )
}

const PaginaAEmpresa = memo(TempPaginaAEmpresa);

export default PaginaAEmpresa;